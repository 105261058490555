import Header from '../../common/Header';
import {
  Button,
  Radio,
  Form,
  Input,
  Space,
  Modal,
  Divider,
  Card,
  Mask,
} from 'antd-mobile';
import { request } from '../../util/request';
import { useEffect, useState } from 'react';

interface Project {
  qllx: string;
  djlx: string;
  sjr: string;
  djyy: string;
  ywlsh: string;
  type: string;
}

export default function Zxxw() {
  const [loading, setLoading] = useState(false);
  const [signLoading, setSignLoading] = useState(false);

  const [projects, setProjects] = useState<Project[]>([]);
  const [show, setShow] = useState(true);

  const getProjects = () => {
    request.get<Project[]>('/inquiry/getProjects').then((data) => {
      setProjects(data);
    });
  };

  useEffect(() => {
    getProjects();
  }, []);

  const onFinish = (values: any) => {
    setLoading(true);
    request
      .post<any>('/inquiry/query', values)
      .then((data) => {
        if (values.type === 'buy') {
          window.location.href = `/#/sqr/${values.ywlsh}`;
        } else {
          window.location.href = `/#/sqr/${values.ywlsh}`;
        }
      })
      .finally(() => setLoading(false));
  };

  const sign = (ywlsh: string) => {
    setSignLoading(true);
    request
      .get<any>(`/sign/send/${ywlsh}`)
      .then((data) => {
        Modal.alert({
          content: '已经发起签署，请注意查收短信',
          closeOnMaskClick: true,
        });
      })
      .finally(() => setSignLoading(false));
  };

  return (
    <>
      <Header name={'在线询问'} />
      <div>
        <Form
          name="form"
          onFinish={onFinish}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loading={loading}
            >
              提交
            </Button>
          }
        >
          <Form.Header>向工作人员询问查询系统</Form.Header>
          <Form.Item
            name="type"
            label="办理人类型"
            rules={[{ required: true }]}
          >
            <Radio.Group onChange={(val) => setShow(val !== 'sell')}>
              <Space direction="vertical">
                <Radio value="buy">买方</Radio>
                <Radio value="sell">卖方</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {show ? (
            <Form.Item
              name="system"
              label="业务办理系统"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value="ow">一窗受理</Radio>
                  <Radio value="bdc">不动产登记</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          ) : null}
          <Form.Item
            name="ywlsh"
            label="业务流水号"
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入业务流水号" />
          </Form.Item>
        </Form>
        <Divider>历史记录</Divider>
        {projects.map((project) => (
          <Card
            key={project.ywlsh}
            title={project.djlx}
            extra={
              <>
                <Button
                  color="primary"
                  fill="outline"
                  size="small"
                  onClick={() =>
                    (window.location.href =
                      project.type === 'buy'
                        ? `/#/sqr/${project.ywlsh}`
                        : `/#/sqr/${project.ywlsh}`)
                  }
                >
                  继续办理
                </Button>
                {project.type === 'buy' ? (
                  <Button
                    color="primary"
                    fill="solid"
                    size="small"
                    onClick={() => sign(project.ywlsh)}
                  >
                    发起签署
                  </Button>
                ) : null}
              </>
            }
          >
            <div>业务号：{project.ywlsh}</div>
            <div>{project.qllx}</div>
            <div>受理人：{project.sjr}</div>
            <div>办理事由：{project.djyy}</div>
          </Card>
        ))}
      </div>
      <Mask color="white" visible={signLoading} />
    </>
  );
}
