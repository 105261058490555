import Header from '../../common/Header';
import fwzt from '../../img/WechatIMG398.png';
import fwtc from '../../img/WechatIMG399.png';
import zmhy from '../../img/zmhy.png';

const { useHistory } = require('react-router-dom');

export default function HouseQuery() {
  const history = useHistory();
  return (
    <>
      <Header name={'房屋查询'} />
      <div style={{ padding: 10 }}>
        <div style={{ textAlign: 'center', marginTop: '20%' }}>
          <img
            onClick={() => history.push({ pathname: '/fwzt' })}
            width={'96%'}
            src={fwzt}
            alt={'暂无图片'}
          />
        </div>
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <img
            onClick={() => history.push({ pathname: '/fwtc' })}
            width={'96%'}
            src={fwtc}
            alt={'暂无图片'}
          />
        </div>
        {/*<div style={{ textAlign: 'center', marginTop: 20 }}>*/}
        {/*  <img width={'96%'} src={zmhy} alt={'暂无图片'} />*/}
        {/*</div>*/}
      </div>
    </>
  );
}
