import React, { useEffect, useRef, useState } from 'react';
import Header from './../common/Header';
import Footer from './../common/Footer';
import { request } from '../util/request';
import { Form, Input, Button, Modal, Steps, Grid, Space } from 'antd-mobile';
import City from '../common/City';
import { ScanningOutline } from 'antd-mobile-icons';
import { init, wx } from '../util/WxUtil';
import { FormInstance } from 'antd-mobile/es/components/form';

interface JdcxResponse {
  actdef_name: string;
  actinsttype: string;
  blry: string;
  actinst_start: string;
  actinst_end: string;
  sxh: string;
}

const { Step } = Steps;

export default function SinfJdcxNJ() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const onFinish = (values: { ywlsh: string }) => {
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    setLoading(true);
    request
      .get<JdcxResponse[]>(`/sinfCloud/jdcxNJ/${values.ywlsh}/${qhdm}`)
      .then((data) => {
        Modal.alert({
          content: (
            <>
              <Steps direction="vertical">
                {data.map((item: JdcxResponse) => {
                  return (
                    <Step
                      key={item.sxh}
                      status="finish"
                      title={`办理环节：${item.actdef_name}`}
                      description={`办理时间：${item.actinst_start}`}
                    />
                  );
                })}
              </Steps>
            </>
          ),
        });
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'进度查询'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
      </div>
      <Form
        style={{ marginTop: 10 }}
        ref={formRef}
        onFinish={onFinish}
        layout="horizontal"
        footer={
          <Grid columns={3} gap={8}>
            <Grid.Item span={2}>
              <Button
                block
                type="submit"
                color="primary"
                size="large"
                loadingText="正在加载"
                loading={loading}
              >
                查询
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                block
                size="large"
                color="primary"
                fill="outline"
                onClick={() => {
                  wx.ready(() => {
                    wx.scanQRCode({
                      needResult: 1,
                      scanType: ['qrCode', 'barCode'],
                      success: function (res: { resultStr: any }) {
                        const lsh = res.resultStr.split('&')[0] || '';
                        formRef.current?.setFieldsValue({ ywlsh: lsh });
                      },
                    });
                  });
                }}
              >
                <Space>
                  <ScanningOutline />
                  <span>扫一扫</span>
                </Space>
              </Button>
            </Grid.Item>
          </Grid>
        }
      >
        <Form.Item
          name="ywlsh"
          label="业务流水号"
          rules={[{ required: true, message: '业务流水号不能为空' }]}
        >
          <Input onChange={console.log} placeholder="请业务流水号" />
        </Form.Item>
      </Form>
      <Footer />
    </>
  );
}
