import Header from '../common/Header';
import { Button } from 'antd-mobile';
import Footer from '../common/Footer';

const { useHistory } = require('react-router-dom');
export default function House() {
  const history = useHistory();
  return (
    <>
      <Header name={'查询需知'} />
      <div style={{ padding: 10 }}>
        <h3 style={{ width: '100%', textAlign: 'center' }}>
          申请人在申请查询前必须仔细阅读须知：
        </h3>
        <div style={{ textIndent: '2em' }}>
          <p>一、网上申请查询应在不动产登记公众号注册并实名认证后进行。</p>
          <p>
            二、该功能支持权利人本人查询个人名下、配偶和未成年子女现有和曾有不动产登记信息查询。
          </p>
          <p>
            三、不动产登记信息查询记录范围为攀枝花市东区、西区、仁和区已办理不动产登记的不动产登记信息。
          </p>
          <p>
            四、查询结果仅证明申请人提供的证件号码在该查询时点所关联不动产登记信息，如提供的证件号码与登记时系统中记录的证件号码不一致导致无法查询的不在此查询范围内。查询的记录为不动产登记结果相关信息，不动产登记详细信息以不动产的登记簿记载的信息为准。
          </p>
          <p>
            五、房屋预售、拟定、备案等相关信息请到市房地产事务中心等单位进行核查；
          </p>
          <p>
            六、申请人承诺严格按照查询目的使用查询结果，不得将查询获得的不动产登记信息用于其他目的，不得泄露查询获得的不动产登记信息，并承担由此产生的法律后果。
          </p>
          <p>七、网上查询不收取任何手续费。</p>
          <p>八、系统开放时间为全天24小时。</p>
          <p>
            我已阅读须知并同意攀枝花市不动产登记中心使用我的个人信息用于此次业务。
          </p>
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <Button
          block
          color={'primary'}
          onClick={() => history.push({ pathname: '/houseItem' })}
        >
          同意
        </Button>
      </div>
      <Footer />
    </>
  );
}
