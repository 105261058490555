import React, {lazy, Suspense, useEffect, useState} from "react";
import "./jdts.css"
import Common from "../../util/Common";


export default function JdtsIndex() {
    const [key, setKey] = useState('JdtsList');
    const [Component, setComponent] = useState<any>(null);
    const [clickData, setClickData] = useState(null);
    /**
     * 生成动态加载组件的方法
     */
    const createComponent = (component: any) => {
        return lazy(() => component);
    }

    const pages = {
        JdtsList: createComponent(import('./JdtsList')),
        JdtsDetails: createComponent(import('./JdtsDetails')),
        JdtsApply: createComponent(import('./JdtsApply')),
    }

    useEffect(() => {
        let timeId = setTimeout(() => {
            setKey('JdtsList');
            setComponent(pages[key as keyof typeof pages]);
        }, 100);

        Common.autoLogin().then();

        return () => {
            clearTimeout(timeId);
        }
    }, [])

    /* 查看详情 */
    function onClick(comp: string, data: any) {
        setKey(comp);
        setComponent(pages[comp as keyof typeof pages]);
        setClickData(data);
    }


    if (!Component) {
        return <div className={'jdts-loading'}>loading...</div>
    }

    return (
        <div style={{width: '100%', height: '100vh', overflowY: 'scroll'}}>
            <Suspense fallback={<div>loading...</div>}>
                <Component onClick={onClick} clickData={clickData}/>
            </Suspense>
        </div>
    )
}