import Header from '../common/Header';
import React, { useEffect, useState } from 'react';
import { Marker } from 'react-bmapgl';
import { Card, Grid, Popup } from 'antd-mobile';
import point from './../icon/point.svg';
import phone from './../icon/phone.svg';
import { request } from '../util/request';

interface Address {
  id: string;
  title: string;
  phone: string;
  address: string;
  pointA: string;
  pointB: string;
}

export default function Address() {
  useEffect(() => {
    // @ts-ignore
    var map = new BMapGL.Map('container');
    // @ts-ignore
    var point = new BMapGL.Point(102.421567, 23.364021);
    // @ts-ignore
    var marker = new BMapGL.Marker(point);
    map.addOverlay(marker);
    map.centerAndZoom(point, 8);
  }, []);
  const [address, setAddress] = useState<Address[]>();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    request.get<Address[]>('/addressInfo/list').then((data) => {
      setAddress(data);
    });
  }, []);
  const pointAddress = (item: Address) => {
    // @ts-ignore
    const map = new BMapGL.Map('container');
    // @ts-ignore
    const point = new BMapGL.Point(item.pointA, item.pointB);
    // @ts-ignore
    const marker = new BMapGL.Marker(point);
    map.addOverlay(marker);
    // @ts-ignore
    map.centerAndZoom(point, 13);
    setVisible(true);
  };

  return (
    <>
      <Header name={'办理地址'} />
      <div
        id="container"
        style={{
          width: '100%',
          height: '18vh',
          position: 'absolute',
          top: 46,
        }}
      ></div>
      <div
        style={{
          marginTop: '20vh',
          padding: 10,
          height: '60vh',
          overflow: 'scroll',
        }}
      >
        {address?.map((item) => {
          return (
            <Card style={{ border: '1px solid #1677ff', marginTop: 20 }}>
              <h2>{item.title}</h2>
              <Grid columns={6} gap={8}>
                <Grid.Item>
                  <div>
                    <img width={36} src={phone} alt={'没有图片'} />
                  </div>
                  <div
                    style={{ marginTop: 10 }}
                    onClick={() => pointAddress(item)}
                  >
                    <img width={36} src={point} alt={'没有图片'} />
                  </div>
                </Grid.Item>
                <Grid.Item span={5}>
                  <div
                    style={{ fontSize: 18, paddingTop: 8, fontWeight: 'bold' }}
                  >
                    <a href={'tel:' + item.phone}>{item.phone}</a>
                  </div>
                  <div
                    style={{
                      marginTop: 10,
                      fontSize: 16,
                      fontWeight: 'bold',
                      paddingTop: 12,
                      color: '#1677ff',
                      cursor: 'pointer',
                    }}
                    onClick={() => pointAddress(item)}
                  >
                    {item.address}
                  </div>
                </Grid.Item>
              </Grid>
            </Card>
          );
        })}
      </div>
    </>
  );
}
