import { useEffect } from 'react';
import axios from 'axios';
import FaceResult from './common/FaceResult';
import Home from 'common/Home';
import Jdcx from './pages/Jdcx';
import Zshy from './pages/Zshy';
import Bszn from './pages/Bszn';
import Ytcf from './pages/Ytcf';
import Yfwfzm from './pages/Yfwfzm';
import Yzym from './pages/Yzym';
import Jt from './pages/Jt';
import Zmhy from './pages/Zmhy';
import Zsxx from './pages/zxxw/Zsxx';
import Zxxw from './pages/zxxw/Zxxw';
import Sdq from './pages/zxxw/Sdq';
import Xwbl from './pages/zxxw/Xwbl';
import Sqr from './pages/zxxw/Sqr';
import Chapter from './pages/Chapter';
import Success from './pages/zxxw/Success';
import PersonalCenter, { UserResponse } from './common/PersonalCenter';
import Zszt from './pages/zszt';
import House from './pages/House';
import HouseItem from './pages/HouseItem';
import HouseInfo from './pages/HouseInfo';
import HouseInfoSf from './pages/housequery/index';
import Fwzt from './pages/housequery/Fwzt';
import Fwtc from './pages/housequery/Fwtc';
import SinfJdcx from './pages/SinfJdcx';
import DzzzYn from './pages/dzzz/DzzzYn';
import Cxsq from './pages/Cxsq';
import WechatPay from './pages/pay/WechatPay';
import Bill from './pages/Bill';
import Address from './pages/Address';
import FaceFw from './pages/housequery/FaceFw';
import FaceFwtc from './pages/housequery/FaceFwtc';
import FaceFwzt from './pages/housequery/FaceFwzt';
import Verification from './pages/housequery/Verification';
import NoticeHH from './pages/NoticeHH';
import Index from './pages/ServiceGuide';
import DzzzFace from './pages/dzzz/DzzzFace';
import Zcfg from './pages/Zcfg';
import Jdts from './pages/Jdts/JdtsIndex';
import Fwpj from './pages/Fwpj/Fwpj';
import Zxyy from './pages/Zxyy/Zxyy';
import Njgsgg from './pages/Gsgg/Gsgg';
import SinfJdcxNJ from './pages/SinfJdcxNJ';
import newBszn from './pages/Bszn/Bszn';
import newZcfg from './pages/Zcfg/Zcfg';
import SNDzzz from './pages/suining/SNDzzz';
import HouseInfoSf_SN from './pages/suining/House';
import FhtSn from './pages/suining/FhtSn';
import SNPay from './pages/suining/pay/Pay';
import HouseCheck from './pages/housequery/HouseCheck';
import ZzhySN from './pages/suining/Zzhy';
import Djzx from './pages/Djzx';
import ZxyySN from './pages/suining/Zxyy';
import Ywgl from './pages/qj/Ywgl';
import { request } from './util/request';
import { Dialog } from 'antd-mobile';

const { HashRouter, Route } = require('react-router-dom');
export default function App() {
  const getCode = () => {
    axios
      .get('/wechat/auth/auth_url')
      .then((res) => {
        if (res.data.code === 0) {
          window.location.href = res.data.data;
        }
      })
      .catch((err) => {
        alert(JSON.stringify(err));
      });
  };
  useEffect(() => {
    // 判断是否是开发环境;
    if (process.env.NODE_ENV === 'development') {
      console.log('开发环境');
      localStorage.setItem('openid', 'o1Z0I5Q8Z0Z0Z0Z0Z0Z0Z0Z0Z0Z1');
      // localStorage.setItem('openid', 'owN1_5z8Tx7PPZUtpiv0lDyfMj-0');
    }
    if (!localStorage.getItem('openid')) {
      // 获取url中的code
      const code = window.location.href.split('?')[1];
      if (code) {
        // 获取code后，获取openid
        // request
        //   .get<{
        //     code: number;
        //     data: any;
        //   }>(`/auth/access_token/${code}`)
        //   .then((res) => {
        //     if (res.code === 0) {
        //       localStorage.setItem('openid', res.data);
        //     }
        //   });
        request
          .post<string>('/auth/access_token', { code: code })
          .then((res) => {
            if (res) {
              localStorage.setItem('openid', res);
            }
          });
      } else {
        getCode();
      }
    } else {
      setTimeout(() => {
        request.get<UserResponse>('/auth/user_info').then(async (data) => {
          if (data == null) {
            const result = await Dialog.confirm({
              content: `未注册，是否前往注册？`,
            });
            if (result) {
              window.location.href = '/#/me';
            }
          }
          // else if (data.faceAuth !== 'yes') {
          //   const result = await Dialog.confirm({
          //     content: `未进行实名认证，是否前往认证？`,
          //   });
          //   if (result) {
          //     window.location.href = '/#/me';
          //   }
          // }
          else {
            localStorage.setItem('user', JSON.stringify(data));
          }
        });
      }, 1000);
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh', overflowY: 'scroll' }}>
      <HashRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/me" component={PersonalCenter} />
        <Route exact path="/jdcx" component={Jdcx} />
        <Route exact path="/zshy" component={Zshy} />
        <Route exact path="/new_bszn" component={newBszn} />
        <Route exact path="/bszn" component={Bszn} />
        <Route exact path="/zxxw" component={Zxxw} />
        <Route exact path="/ytcf" component={Ytcf} />
        <Route exact path="/yfwfzm" component={Yfwfzm} />
        <Route exact path="/yzym" component={Yzym} />
        <Route exact path="/jt" component={Jt} />
        <Route exact path="/faceAuth" component={FaceResult} />
        <Route exact path="/zmhy" component={Zmhy} />
        <Route exact path="/zsxx/:ywlsh" component={Zsxx} />
        <Route exact path="/sdq/:ywlsh" component={Sdq} />
        <Route exact path="/xwbl/:ywlsh" component={Xwbl} />
        <Route exact path="/sqr/:ywlsh" component={Sqr} />
        <Route exact path="/chapter" component={Chapter} />
        <Route exact path="/zszt" component={Zszt} />
        <Route exact path="/house" component={House} />
        <Route exact path="/success" component={Success} />
        <Route exact path="/houseItem" component={HouseItem} />
        <Route exact path="/houseInfo" component={HouseInfo} />
        <Route exact path="/houseSf" component={HouseInfoSf} />
        <Route exact path="/houseSf_sn" component={HouseInfoSf_SN} />
        <Route exact path="/fht_sn" component={FhtSn} />
        <Route exact path="/fwzt" component={Fwzt} />
        <Route exact path="/fwtc" component={Fwtc} />
        <Route exact path="/jdcxSf" component={SinfJdcx} />
        <Route exact path="/jdcxSfNJ" component={SinfJdcxNJ} />
        <Route exact path="/dzzzYn" component={DzzzYn} />
        <Route exact path="/dzzzFace" component={DzzzFace} />
        <Route exact path="/cxsq" component={Cxsq} />
        <Route exact path="/wechat_pay" component={WechatPay} />
        <Route exact path="/bill" component={Bill} />
        <Route exact path="/address" component={Address} />
        <Route exact path="/face_fw" component={FaceFw} />
        <Route exact path="/face_fwtc" component={FaceFwtc} />
        <Route exact path="/face_fwzt" component={FaceFwzt} />
        <Route exact path="/notice_hh" component={NoticeHH} />
        <Route exact path="/service_guide" component={Index} />
        <Route exact path="/new_zcfg" component={newZcfg} />
        <Route exact path="/zcfg" component={Zcfg} />
        <Route exact path="/jdts" component={Jdts} />
        <Route exact path="/verification" component={Verification} />
        <Route exact path="/njgsgg" component={Njgsgg} />
        <Route exact path="/fwpj" component={Fwpj} />
        <Route exact path="/zxyy" component={Zxyy} />
        <Route exact path="/sndzzz" component={SNDzzz} />
        <Route exact path="/pay_suining" component={SNPay} />
        <Route exact path="/house_check" component={HouseCheck} />
        <Route exact path="/zzhy_sn" component={ZzhySN} />
        <Route exact path="/djzx" component={Djzx} />
        <Route exact path="/zxyy_sn" component={ZxyySN} />
        <Route exact path="/ywgl" component={Ywgl} />
      </HashRouter>
    </div>
  );
}
