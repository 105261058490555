import React, { useEffect } from 'react';
import { wx, init } from '../../util/WxUtil';
import Header from '../../common/Header';
import sm from '../../icon/sm.svg';
import { Popup } from 'antd-mobile';
import PdfViewSinf from '../../common/PdfViewSinf';
import { request } from '../../util/request';

export default function HouseCheck() {
  const [visible, setVisible] = React.useState(false);
  const [fileName, setFileName] = React.useState('');
  useEffect(() => {
    init();
  }, []);
  return (
    <>
      <Header name={'证明核验'} />
      <div
        onClick={() => {
          wx.ready(() => {
            wx.scanQRCode({
              needResult: 1,
              scanType: ['qrCode', 'barCode'],
              success: function (res: { resultStr: any }) {
                const result = res.resultStr.split(',')[1]; // 当needResult 为 1 时，扫码返回的结果
                setVisible(true);
                request
                  .get<string>(`/houseQuery/check/${result}`)
                  .then((fileName) => {
                    setFileName(fileName);
                  });
              },
            });
          });
        }}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <div>
          <img src={sm} alt="" width={100} style={{ marginTop: '10vh' }} />
        </div>
        <div style={{ color: '#009FE8', fontSize: 16 }}>扫一扫</div>
      </div>
      <Popup
        visible={visible}
        showCloseButton
        onClose={() => {
          setVisible(false);
        }}
      >
        <div style={{ height: '96vh' }}>
          <PdfViewSinf fileName={fileName} />
        </div>
      </Popup>
    </>
  );
}
