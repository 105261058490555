import React, {useEffect, useRef, useState} from 'react';
import Header from "../../common/Header";
import {requestEstate} from "../../util/requestEstate";
import {DotLoading, Toast, SearchBar, Modal} from "antd-mobile";
import {Pagination} from "antd";
import './Zcfg.css';

interface Policy {
	id: string,
	title: string,
	content: string,
	source: string,
	publishTime: string
}

function Zcfg() {
	const [policyList, setPolicyList] = useState<Array<Policy>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const pagination = useRef<{
		current: number,//当前页,从1开始
		size: number,//每页数据个数
		total: number,//数据总数
	}>({
		current: 1,
		size: 10,
		total: 0
	});
	const modalRef = useRef(null);

	//获取数据列表
	async function getList(value?: string) {
		setLoading(true);
		try {
			const res = await requestEstate.get<{
				result: {
					records: Array<Policy>,
					pages: number,
					size: number,
					total: number,
					current: number
				}
			}>('/publish_content/publishContent/list', {
				type: '2',
				title: value,
				pageSize: pagination.current.size,
				pageNo: value ? 1 : pagination.current.current
			});
			setPolicyList(res.result.records);
			pagination.current.current = res.result.current;
			pagination.current.size = res.result.size;
			pagination.current.total = res.result.total;
		} catch (e) {
			console.log(e);
			Toast.show({icon: 'fail', content: '网络错误', duration: 5000});
		}
		setLoading(false);
	}

	//切换页码
	async function paginationChange(page: number, pageSize: number) {
		pagination.current.current = page;
		await getList();
	}

	//显示详情弹窗
	function showDetail(policy: Policy) {
		// policy.content = policy.content.replaceAll('<img', '<img style="width:100%"');
		Modal.alert({
			title: policy.title,
			content: <div dangerouslySetInnerHTML={{__html: policy.content}}></div>,
			closeOnMaskClick: true,
			getContainer: modalRef.current
		});
	}

	useEffect(() => {
		getList().then();
	}, []);
	return (
		<div className={'zcfg-page'}>
			<Header name={'政策法规'}/>
			<div className={'page-content'}>
				<div style={{display: loading ? 'flex' : 'none'}} className='loading'>
					<span>数据加载中</span>
					<DotLoading color='currentColor'/>
				</div>
				<div className={'search-bar'}>
					<SearchBar placeholder='请输入关键词' showCancelButton onSearch={(value) => getList(value)}/>
				</div>
				<div className={'policy-list'}>
					{
						policyList.map(policy => {
							return (
								<div className={'policy-item'} key={policy.id} onClick={() => showDetail(policy)}>
									<div className={'policy-title'}>{policy.title}</div>
									<div className={'policy-tips'}>
										<span>发布时间：{policy.publishTime}</span>
										<span>来源：{policy.source}</span>
									</div>
								</div>
							)
						})
					}
				</div>
				<Pagination style={{display: policyList.length !== 0 ? 'block' : 'none'}} className={'pagination'}
										current={pagination.current.current} total={pagination.current.total} onChange={paginationChange}/>
				<div className='empty' style={{display: policyList.length === 0 && !loading ? 'block' : 'none'}}>
					暂无政策法规数据
				</div>
				<div className={'detail-modal'} ref={modalRef}></div>
			</div>
		</div>
	);
}

export default Zcfg;
