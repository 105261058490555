import Footer from '../common/Footer';
import Header from '../common/Header';
import { request, responseTypes } from '../util/request';
import { UserResponse } from '../common/PersonalCenter';
import { useEffect, useState } from 'react';
import { Button, Card, List, Tag, Modal, Toast } from 'antd-mobile';

interface Cxsq {
  code: string;
  createTime: string;
  endTime: string;
  expire: boolean;
}

export default function Cxsq() {
  const [user, setUser] = useState<UserResponse>();
  const [cxsqList, setCxsqList] = useState<Cxsq[]>([]);
  const [loading, setLoading] = useState(false);
  const loadUser = () => {
    request.get<UserResponse>('/auth/user_info').then((data) => {
      setUser(data);
    });
  };

  const loadCxsqList = () => {
    request.get<Cxsq[]>('/cxsq/list').then((data) => {
      setCxsqList(data);
    });
  };
  // 实现复制功能
  const copy = (text: string) => {
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Toast.show('复制成功,' + text);
    }
    document.body.removeChild(input);
  };

  useEffect(() => {
    loadUser();
    loadCxsqList();
  }, []);

  return (
    <div>
      <Header name={'查询授权'} />
      {
        // 显示用户信息
        user == null ? (
          <div>未登录</div>
        ) : (
          <List header="用户信息">
            <List.Item>姓名：{user.name}</List.Item>
            <List.Item>手机号：{user.phone}</List.Item>
            <List.Item>身份证号：{user.idCard}</List.Item>
          </List>
        )
      }
      <div style={{ padding: 8 }}>
        <Button
          block
          color="primary"
          loading={loading}
          onClick={() => {
            setLoading(true);
            request
              .get<string>('/cxsq/createCode')
              .then((data) => {
                Toast.show('生成成功，' + data);
              })
              .finally(() => {
                setLoading(false);
                loadCxsqList();
              });
          }}
        >
          生成查询授权码
        </Button>
      </div>
      {cxsqList.length === 0 ? (
        <div style={{ marginTop: 20, textAlign: 'center' }}>无数据</div>
      ) : (
        cxsqList.map((cxsq, index) => (
          <List header="查询授权码" key={index} style={{ marginTop: 10 }}>
            <List.Item onClick={() => copy(cxsq.code)} extra="点击复制授权码">
              授权码：{cxsq.code}
            </List.Item>
            <List.Item>创建时间：{cxsq.createTime}</List.Item>
            <List.Item>结束时间：{cxsq.endTime}</List.Item>
            <List.Item>
              有效状态：
              {cxsq.expire ? (
                <Tag color="success">有效</Tag>
              ) : (
                <Tag color="default">无效</Tag>
              )}
            </List.Item>
          </List>
        ))
      )}
      <Footer />
    </div>
  );
}
