import React, { useEffect } from 'react';
import Header from '../../common/Header';
import { request } from '../../util/request';
import { Button, Card, Divider, Form, Input, Modal, Popup } from 'antd-mobile';
import { Business } from './Xwbl';
import { FormInstance } from 'antd-mobile/es/components/form';

interface Sqr {
  id: string;
  sqrmc: string;
  zjh: string;
  zjlx: string;
  sqrlb: string;
  txdz: string;
  lxdh: string;
  ywlsh: string;
}

function Sqr(props: any) {
  const { ywlsh } = props.match.params;
  const formRef = React.createRef<FormInstance>();
  const [sqr, setSqr] = React.useState<Sqr[]>([]);
  const [visible, setVisible] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [edit, setEdit] = React.useState(true);
  const [sqrId, setSqrId] = React.useState('');
  const [business, setBusiness] = React.useState<Business>();

  const getSdq = () => {
    request.get<Sqr[]>(`/inquiry/getSqr/${ywlsh}`).then((data) => {
      setSqr(data);
    });
  };

  const tips = (sqr: Sqr) => {
    let tip = '';
    if (!sqr.lxdh) {
      tip += '联系电话 ';
    }
    if (!sqr.txdz) {
      tip += '通讯地址 ';
    }
    if (tip !== '') {
      tip += '未填写';
    }
    return tip;
  };
  const getBusiness = () => {
    request.get<Business>(`/inquiry/getBusiness/${ywlsh}`).then((data) => {
      // 设置表单值
      setEdit(data.submit === 'y');
      setBusiness(data);
    });
  };
  const onFinish = (values: Sqr) => {
    setLoading(true);
    values.id = sqrId;
    request
      .put<any>('/inquiry/updateSqr', values)
      .then(() => {
        getSdq();
        setVisible(false);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getSdq();
    getBusiness();
  }, []);

  const editSqr = (item: Sqr) => {
    setVisible(true);
    setSqrId(item.id);
    formRef.current?.setFieldsValue(item);
  };

  return (
    <>
      <Header name={'申请人信息'} />
      <div>
        {sqr.length === 0 ? (
          <Divider>没有申请人信息</Divider>
        ) : (
          sqr.map((item, index) => {
            return (
              <Card
                style={{ marginTop: 10 }}
                key={index}
                title={item.sqrmc}
                extra={
                  <>
                    <span style={{ color: 'red' }}>{tips(item)}</span>
                    <Button
                      disabled={edit}
                      color="primary"
                      fill="solid"
                      size="small"
                      onClick={() => editSqr(item)}
                    >
                      编辑
                    </Button>
                  </>
                }
              >
                <div>申请人:{item.sqrmc}</div>
                <div>证件号:{item.zjh}</div>
                <div>联系电话:{item.lxdh}</div>
                <div>通讯地址:{item.txdz}</div>
              </Card>
            );
          })
        )}
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
          bodyStyle={{ height: '62vh' }}
        >
          <Form
            name="form"
            ref={formRef}
            onFinish={onFinish}
            footer={
              <Button
                block
                type="submit"
                color="primary"
                size="large"
                loading={loading}
              >
                保存
              </Button>
            }
          >
            <Form.Header>申请信息</Form.Header>
            <Form.Item name="id" label="id" style={{ display: 'none' }}>
              <Input placeholder="id" />
            </Form.Item>
            <Form.Item
              name="lxdh"
              label="联系电话"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入联系电话" />
            </Form.Item>
            <Form.Item
              name="txdz"
              label="通讯地址"
              rules={[{ required: true }]}
            >
              <Input placeholder="请输入通讯地址" />
            </Form.Item>
          </Form>
        </Popup>
        <div style={{ padding: 10 }}>
          <Button
            block
            color="primary"
            size="large"
            onClick={() => {
              let flog = false;
              sqr.forEach((item) => {
                if (item.lxdh && item.txdz) {
                  flog = true;
                }
              });
              if (flog) {
                window.location.href =
                  business?.type === 'buy'
                    ? `/#/sdq/${ywlsh}`
                    : `/#/xwbl/${ywlsh}`;
              } else {
                Modal.alert({
                  content: '申请人信息不完整,请完善后再进行下一步',
                  closeOnMaskClick: true,
                });
              }
            }}
          >
            下一步
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sqr;
