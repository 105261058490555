import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import { request, responseTypes } from '../../util/request';
import {
  Form,
  Input,
  Button,
  Popup,
  Tag,
  Divider,
  Grid,
  Mask,
  AutoCenter,
  Card,
  Selector,
  TextArea,
} from 'antd-mobile';
import { EditSOutline } from 'antd-mobile-icons';
import { FormInstance } from 'antd-mobile/es/components/form';

interface ZsztResponse {
  bdcdyid: string;
  jzmj: string;
  zl: string;
  zrzbdcyid: string;
  zdbdcyid: string;
  text: string;
  status: string;
  select: Array<string> | undefined;
}

export default function Index() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [curr, setCurr] = useState<ZsztResponse>({} as ZsztResponse);

  const [zsztList, setZsztList] = useState<ZsztResponse[]>([]);
  const onFinish = (values: ZsztResponse) => {
    setLoading(true);
    const status = values.select?.[0];
    values.status = status ? status : '0';
    request
      .post<responseTypes<any>>('/zsxx/updateOrAdd', values)
      .then((res) => {
        setVisible(false);
        loadZszt();
      })
      .finally(() => setLoading(false));
  };

  const formRef = React.createRef<FormInstance>();

  const loadZszt = () => {
    setLoading(true);
    request
      .get<ZsztResponse[]>('/zsxx/list')
      .then((data) => {
        setZsztList(data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadZszt();
  }, []);
  useEffect(() => {
    if (!curr) return;
    formRef?.current?.setFieldsValue({
      select:
        curr.status === null || curr.status === '0'
          ? ['0']
          : curr.status === 'zz'
          ? ['zz']
          : ['zs'],
      text: curr.text === null ? '' : curr.text,
      bdcdyid: curr.bdcdyid,
      zrzbdcyid: curr.zrzbdcyid,
      jzmj: curr.jzmj,
      zl: curr.zl,
      zdbdcyid: curr.zdbdcyid,
    });
  }, [curr]);

  return (
    <>
      <Header name={'租售状态'} />
      {zsztList.map((item, index) => {
        return (
          <Card title={item.zl} style={{ marginTop: 20 }} key={index}>
            <Grid columns={4} gap={8}>
              <Grid.Item span={3}>
                <div>
                  <span>
                    租售状态：
                    {item.status === null || item.status === '0' ? (
                      <Tag>无</Tag>
                    ) : item.status === 'zz' ? (
                      <Tag color="green">在租</Tag>
                    ) : (
                      <Tag color="danger">在售</Tag>
                    )}
                  </span>
                  <Divider direction="vertical" />
                  <span>{item.jzmj}㎡</span>
                </div>
                <div style={{ marginTop: 8 }}>
                  租售信息：{item.text === null ? '/' : item.text}
                </div>
              </Grid.Item>
              <Grid.Item style={{ position: 'relative' }}>
                <AutoCenter>
                  <Button
                    color="primary"
                    size={'small'}
                    onClick={() => {
                      setVisible(true);
                      setCurr(item);
                    }}
                  >
                    <EditSOutline />
                    <span>编辑</span>
                  </Button>
                </AutoCenter>
              </Grid.Item>
            </Grid>
          </Card>
        );
      })}
      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        bodyStyle={{ height: '70vh' }}
      >
        <Form
          name="form"
          ref={formRef}
          onFinish={onFinish}
          initialValues={{
            select:
              curr.status === null
                ? ['0']
                : curr.status === '1'
                ? ['zz']
                : ['zs'],
            text: curr.text === null ? '' : curr.text,
            bdcdyid: curr.bdcdyid,
            zrzbdcyid: curr.zrzbdcyid,
            jzmj: curr.jzmj,
            zl: curr.zl,
            zdbdcyid: curr.zdbdcyid,
          }}
          footer={
            <Button block type="submit" color="primary" size="large">
              提交
            </Button>
          }
        >
          <Form.Header>状态编辑</Form.Header>
          <Form.Item name="select" label="租售状态">
            <Selector
              columns={3}
              options={[
                { label: '无', value: '0' },
                { label: '拟租', value: 'zz' },
                { label: '拟售', value: 'zs' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="text"
            label="租售相关信息"
            rules={[{ required: true, min: 1, max: 50 }]}
          >
            <TextArea placeholder="请输入50个字以内的租售相关信息" rows={5} />
          </Form.Item>
          <Form.Item
            style={{ display: 'none' }}
            name="bdcdyid"
            label="不动产单元id"
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ display: 'none' }} name="jzmj" label="建筑面积">
            <Input />
          </Form.Item>
          <Form.Item style={{ display: 'none' }} name="zl" label="坐落">
            <Input />
          </Form.Item>
          <Form.Item
            style={{ display: 'none' }}
            name="zrzbdcyid"
            label="自然幢id"
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ display: 'none' }} name="zdbdcyid" label="宗地id">
            <Input />
          </Form.Item>
        </Form>
      </Popup>
      <Footer />
      <Mask color="white" visible={loading} />
    </>
  );
}
