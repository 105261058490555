import { useState } from 'react';
import Header from './../common/Header';
import Footer from './../common/Footer';
import MyWaterMark from '../common/MyWaterMark';
import { request } from '../util/request';
import { Form, Input, Button, Popup, List, NoticeBar } from 'antd-mobile';

interface JdcxResponse {
  qlrmc: string;
  gyfs: string;
  zl: string;
  bdcdyh: string;
  qllx: string;
  qlxz: string;
  yt: string;
  syqx: string;
  qlqtqk: string;
  mj: string;
  qzh: string;
}

export default function Zshy() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<JdcxResponse>();
  const onFinish = (values: { qzh: string }) => {
    setLoading(true);
    request
      .post<JdcxResponse>('/bdc/zshy', values)
      .then((data) => {
        setVisible(true);
        setData(data);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Header name={'证书核验'} />
      <div>
        <Form
          onFinish={onFinish}
          layout="horizontal"
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loadingText="正在加载"
              loading={loading}
            >
              查询
            </Button>
          }
        >
          <Form.Item
            name="qzh"
            label="请输入权证号"
            rules={[
              { required: true, message: '权证号最少需要输入5位', min: 5 },
            ]}
          >
            <Input onChange={console.log} placeholder="请输入权证号" />
          </Form.Item>
          <NoticeBar
            style={{ marginTop: 10 }}
            content="权书号支持输入数字最少5位"
            color="info"
          />
        </Form>
        <Popup
          visible={visible}
          onMaskClick={() => {
            setVisible(false);
          }}
          bodyStyle={{ height: '60vh' }}
        >
          <MyWaterMark />
          <List header={data?.qzh}>
            <List.Item>权利人：{data?.qlrmc}</List.Item>
            <List.Item>共有方式：{data?.gyfs}</List.Item>
            <List.Item>不动产单元号：{data?.bdcdyh}</List.Item>
            <List.Item>坐落：{data?.zl}</List.Item>
            <List.Item>权利类型：{data?.qllx}</List.Item>
            <List.Item>
              权利性质：{data?.qlxz},用途：{data?.yt}
            </List.Item>
            <List.Item>权利起止时间：{data?.syqx}</List.Item>
            <List.Item>{data?.mj}</List.Item>
            <List.Item>权利其他情况：{data?.qlqtqk}</List.Item>
          </List>
        </Popup>
      </div>
      <Footer />
    </>
  );
}
