import { WaterMark } from 'antd-mobile';

export default function MyWaterMark() {
  // 获取当前时间
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  const now = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  return (
    <>
      <WaterMark
        content={now}
        gapX={12}
        gapY={20}
        fullPage={true}
      />
    </>
  );
}
