import { Footer as AntFooter } from 'antd-mobile';

export default function Footer() {
  return (
    <>
      <AntFooter
        label={
          <div>
            <p>{localStorage.getItem('cityName')}</p>
          </div>
        }
      />
    </>
  );
}
