import Header from '../common/Header';
import React from 'react';

import {
  Form,
  Input,
  Button,
  Dialog,
  TextArea,
  DatePicker,
  Selector,
  Slider,
  Stepper,
  Switch,
} from 'antd-mobile';

export default function Bill() {
  const onFinish = (values: any) => {
    Dialog.alert({
      content: <pre>{JSON.stringify(values, null, 2)}</pre>,
    });
  };

  return (
    <>
      <Header name={'发票查询'} />
      <Form
        layout="horizontal"
        style={{ marginTop: '20%', borderTop: 'none' }}
        footer={
          <Button block type="submit" color="primary" size="large">
            提交
          </Button>
        }
      >
        <Form.Item
          name="ywlsh"
          label="业务流水号"
          rules={[{ required: true, message: '业务流水号不能为空' }]}
        >
          <Input placeholder="请输入业务流水号" />
        </Form.Item>
      </Form>
    </>
  );
}
