import React, { memo, useState } from 'react';
import { baseURL } from '../util/request';
import { Button, Modal } from 'antd-mobile';
import { pdfjs, Document, Page } from 'react-pdf';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default memo((data: any) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const phoneName = () => {
    const u = navigator.userAgent;
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
    if (isAndroid) {
      return 'android';
    } else if (isiOS) {
      return 'ios';
    } else {
      return 'other';
    }
  };

  const download = () => {
    const name = phoneName();
    // if (name === 'android') {
    // 复制链接
    const input = document.createElement('input');
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', data.url);
    document.body.appendChild(input);
    input.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Modal.alert({ title: '复制成功', content: '请在浏览器中打开' });
    }
    // } else if (name === 'ios') {
    //   window.location.href = data.url;
    // }
    return;
  };

  // @ts-ignore
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <p>
        <Button
          style={{ marginLeft: 10, marginRight: 10 }}
          size={'small'}
          color="primary"
          onClick={() => setPageNumber((prevstate) => prevstate - 1)}
          disabled={pageNumber === 1}
        >
          上一页
        </Button>
        <span style={{ fontSize: 14, fontWeight: '600' }}>
          {pageNumber}/{numPages}页
        </span>
        <Button
          style={{ marginLeft: 10 }}
          size={'small'}
          color="primary"
          onClick={() => setPageNumber((prevstate) => prevstate + 1)}
          disabled={pageNumber === numPages}
        >
          下一页
        </Button>
        <Button style={{ marginLeft: 10 }} size={'small'} onClick={download}>
          {'复制地址下载'}
        </Button>
      </p>
      <div style={{ overflow: 'scroll', height: '96vh' }}>
        {/*<iframe*/}
        {/*  id="iframe"*/}
        {/*  src={data.url}*/}
        {/*  width="100%"*/}
        {/*  height="100%"*/}
        {/*  frameBorder={0}*/}
        {/*  marginWidth={0}*/}
        {/*  marginHeight={0}*/}
        {/*  scrolling={'no'}*/}
        {/*></iframe>*/}
        {/*<iframe*/}
        {/*  src={data.url}*/}
        {/*  style={{*/}
        {/*    width: '100%',*/}
        {/*    height: '80vh',*/}
        {/*    overflowY: 'scroll',*/}
        {/*    border: 'none',*/}
        {/*    position: 'absolute',*/}
        {/*  }}*/}
        {/*></iframe>*/}
        <Document
          file={data.url}
          loading={<div>加载中...</div>}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <div>{data.url}</div>
          <Page
            pageNumber={pageNumber}
            scale={0.68}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </div>
  );
});
