import { useState } from 'react';
import Header from './../common/Header';
import Footer from './../common/Footer';
import { request } from '../util/request';
import { Form, Input, Button, Modal, Steps } from 'antd-mobile';

interface JdcxResponse {
  xmmc: string;
  stepList: [];
}

const { Step } = Steps;

export default function Jdcx() {
  const [loading, setLoading] = useState(false);
  const onFinish = (values: { name: string }) => {
    setLoading(true);
    request
      .get<JdcxResponse>(`/bdc/jdcx/${values.name}`)
      .then((res) => {
        Modal.alert({
          content: (
            <>
              <h4>{res.xmmc}</h4>
              <Steps direction="vertical">
                {res.stepList.map((item: any) => {
                  return (
                    <Step
                      key={item.id}
                      status="finish"
                      title={`办理人：
                               ${
                                 item.staffName === null ? '--' : item.staffName
                               } 
                               ${
                                 item.actdefName === null
                                   ? '--'
                                   : item.actdefName
                               } 
                               ${item.status === null ? '--' : item.status}`}
                      description={`${
                        item.startTime === null ? '--' : item.startTime
                      }'-'
                                 ${
                                   item.endTime === null ? '--' : item.endTime
                                 }`}
                    />
                  );
                })}
              </Steps>
            </>
          ),
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Header name={'进度查询'} />
      <div>
        <Form
          onFinish={onFinish}
          layout="horizontal"
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              loadingText="正在加载"
              loading={loading}
            >
              查询
            </Button>
          }
        >
          <Form.Item
            name="name"
            label="业务流水号"
            rules={[{ required: true, message: '业务流水号不能为空' }]}
          >
            <Input onChange={console.log} placeholder="请业务流水号" />
          </Form.Item>
        </Form>
      </div>
      <Footer />
    </>
  );
}
