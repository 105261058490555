import Header from "../../common/Header";
import Footer from "../../common/Footer";
import React, {useEffect, useState} from "react";
import {Form, Input, Picker, Space, Button, Selector, Checkbox, TextArea, Toast, DotLoading} from "antd-mobile";
import './Fwpj.css'
import {request} from "../../util/request";
import {RightOutline} from "antd-mobile-icons";

interface City {
	label: string;
	value: string;
}

interface Evaluate {
	ywh: string,
	xzqh: string[],
	fwpj: string[],
	pjly: string[],
	pjnr: string
}

export default function Fwpj() {
	const [form] = Form.useForm();
	//行政区划列表
	const [xzqhList, setXzqhList] = useState<(City[])[]>([[]]);
	//选择的行政区划代码
	const [xzqh, setXzqh] = useState<(string | number | null)[]>([]);
	//是否正在提交数据
	const [loading, setLoading] = useState<Boolean>(false);
	//办件评价字典
	const bjpjOptions: { label: string, value: string }[] = [
		{
			label: '非常满意',
			value: '5'
		},
		{
			label: '满意',
			value: '4'
		},
		{
			label: '基本满意',
			value: '3'
		},
		{
			label: '不满意',
			value: '2'
		},
		{
			label: '非常不满意',
			value: '1'
		},
	];
	//评价理由
	const pjlyData: string[] = [
		'一张清单告知全部申请材料',
		'提供申报材料样本',
		'跑大厅一次办完',
		'在承诺时间内办结',
		'服务热情效率高',
	];
	//评价内容是否必填
	const [pjlrRequired, setPjlrRequired] = useState<boolean>(false);

	//选择行政区划代码
	function onXzqhChange(value: (string | number | null)[]) {
		setXzqh(value);
		//需要手动设置表单的xzqh值，表单组件无法同步Picker组件的数据
		form.setFieldValue('xzqh', value);
	}

	//表单数据发生变化
	function onValuesChange(changedValues: {
		ywh?: string,
		xzqh?: string[],
		fwpj?: string[],
		pjly?: string[],
		pjnr?: string
	}, allValues: Evaluate) {
		if (changedValues.fwpj) {
			if (changedValues.fwpj[0] === '1'||changedValues.fwpj[0] === '2') {
				setPjlrRequired(true);
			} else {
				setPjlrRequired(false);
			}
		}
	}

	//提交表单
	function onSubmit(formData: Evaluate) {
		setLoading(true);
		request.post('/fwpj/addEvaluate', {
			"ywh": formData.ywh, //业务号
			"qxdm": formData.xzqh[0], //区县代码
			"bjpj": formData.fwpj[0], //办件评价
			"pjly": formData.pjly.join(','), //评价理由(多个内容用逗号分隔)
			"pjnr": formData.pjnr //评价内容
		}).then(() => {
			Toast.show({
				content: '提交成功！',
				icon: 'success'
			});
			//重置表单数据
			form.resetFields();
			setXzqh([]);
		}).finally(() => {
			setLoading(false);
		})
	}

	useEffect(() => {
		request.get<City[]>('/sinfConfig/list').then((data) => {
			setXzqhList([data]);
		});
	}, []);

	return (
		<div className={'fwpj-page'}>
			<Header name={'服务评价'}/>
			<div className='container'>
				<div style={{display: loading ? 'flex' : 'none'}} className='loading'>
					<span>数据提交中</span>
					<DotLoading color='currentColor'/>
				</div>
				<Form layout='horizontal' onFinish={onSubmit} form={form} className={'fwpj-form'}
							onValuesChange={onValuesChange}
							footer={
								<Button block type='submit' color='primary' size='large'>
									提交
								</Button>
							}>
					<Form.Item name='ywh' label='业务号' rules={[{required: true, message: '业务号不能为空'}]}
										 layout='horizontal'>
						<Input placeholder='请输入业务号' clearable/>
					</Form.Item>
					<Form.Item name='xzqh' label='行政区划' rules={[{required: true, message: '请选择行政区划'}]}
										 layout='horizontal'>
						<Picker
							columns={xzqhList}
							value={xzqh}
							onConfirm={onXzqhChange}
						>
							{(items, {open}) => {
								return (
									<Space align='center' onClick={open}>
										{items.every(item => item === null) ? '请选择行政区' : items.map(item => item?.label ?? '请选择行政区').join('-')}
										<div className='picker-item'><RightOutline className='icon'/></div>
									</Space>
								)
							}}
						</Picker>
					</Form.Item>
					<Form.Item name='fwpj' label='服务评价' rules={[{required: true, message: '请选择服务评价'}]}>
						<Selector options={bjpjOptions}></Selector>
					</Form.Item>
					<div className='pjly-title'>感谢您的评价! 请勾选或录入您评价的理由，以帮助各地区各部门更好地提升政务服务绩效。
					</div>
					<Form.Item name='pjly' label='评价理由' rules={[{required: true, message: '请勾选评价理由'}]}>
						<Checkbox.Group>
							{
								pjlyData.map((item, index) => <Checkbox value={item} key={index}>{item}</Checkbox>)
							}
						</Checkbox.Group>
					</Form.Item>
					<Form.Item name='pjnr' label='评价内容' rules={[{required: pjlrRequired, message: '请填写您的评价'}]}>
						<TextArea placeholder='评价字数不超过150字' maxLength={150} autoSize={{minRows: 3}}></TextArea>
					</Form.Item>
				</Form>
			</div>
			<Footer/>
		</div>
	)
}
