import React from 'react';
import { Button, Result } from 'antd-mobile';
import Header from '../../common/Header';

function Success() {
  return (
    <>
      <Header name={' '} />
      <Result
        status='success'
        title='操作成功'
        description='提交成功，请注意查收短信！'
      />
      <div style={{ padding: 10 }}>
        <Button block color='primary' size='large' onClick={()=>window.location.href='#/zxxw'}>
          返回
        </Button>
      </div>
    </>
  );
}

export default Success;
