import Header from '../../common/Header';
import React, { useRef, useState } from 'react';
import { Button, Form, Input } from 'antd-mobile';
import City from '../../common/City';
import wechat_sya from './../../icon/wechat_sya.svg';

const extraPart = {
  borderLeft: 'solid 1px #eeeeee',
  paddingLeft: '12px',
  fontSize: '17px',
  lineHeight: '22px',
};
export default function WechatPay() {
  const cityRef = useRef<HTMLDivElement>(null);
  const [value, setValue] = useState('');
  const queryFwtc = () => {
    // @ts-ignore
    const qhdm = cityRef.current && cityRef.current.getData();
    alert(value);
  };
  return (
    <>
      <Header name={'在线缴费'} />
      <div style={{ padding: 10 }}>
        <City ref={cityRef} />
        <Form layout="horizontal" style={{ marginTop: 10 }}>
          <Form.Item
            label="业务号"
            extra={
              <div
                style={extraPart}
                onClick={() => {
                  alert('点击了');
                }}
              >
                {/*<img src={'../../icon/wechat_sya.png'} alt={'暂时图片'}></img>*/}
                <img width={40} src={wechat_sya} alt={'暂时图片'}></img>
              </div>
            }
          >
            <Input
              placeholder="请输入验证码"
              value={value}
              onChange={(val) => {
                setValue(val);
              }}
              clearable
            />
          </Form.Item>
        </Form>
        <Button
          style={{ marginTop: 20 }}
          block
          color="primary"
          onClick={queryFwtc}
          size={'large'}
        >
          查询
        </Button>
      </div>
    </>
  );
}
