import React, { useState, useEffect } from 'react';
import { request } from '../../../util/requestPay';
import { Card, ErrorBlock, Grid, Tabs, Toast } from 'antd-mobile';
import City from '../../../common/City';
import { Form, Input, Button, Modal } from 'antd-mobile';
import Footer from '../../../common/Footer';
import { init } from '../../../util/WxUtil';
import { Order } from './pay.type';
import money from '../../../icon/money.svg';

export default function Payment() {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const [payLoading, setPayLoading] = useState(false);
  const onFinish = (values: { ywlsh: string }) => {
    orderList(values.ywlsh, '510900');
  };
  useEffect(() => {
    init();
  }, []);

  const orderList = (ywlsh: string, xzqdm: string) => {
    setLoading(true);
    request
      .get<Order[]>(`/suining_pay/payOrderListSN/${ywlsh}/${xzqdm}`)
      .then((data) => {
        setOrders(data);
      })
      .catch((err) => {
        Toast.show(err.message);
      })
      .finally(() => setLoading(false));
  };

  const payUrl = (order: Order) => {
    setLoading(true);
    setPayLoading(true);
    request
      .post<string>('/suining_pay/qrCode', order)
      .then((url) => {
        window.location.href = url;
      })
      .catch((err) => {
        Toast.show(err.message);
        setPayLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Form
        onFinish={onFinish}
        name="form"
        footer={
          <Button block type="submit" color="primary" size="large">
            查询
          </Button>
        }
      >
        <Form.Item
          name="ywlsh"
          label="业务号"
          rules={[{ required: true, message: '请输入业务号' }]}
        >
          <Input placeholder="请输入业务号" />
        </Form.Item>
      </Form>

      {orders.length === 0 ? (
        <ErrorBlock
          title={'请联系工作人员确认是否已受理'}
          description={'无缴费信息'}
          status="empty"
        />
      ) : null}
      {orders.map((order) => {
        return (
          <Card
            style={{ marginTop: 20 }}
            title={
              <div style={{ fontWeight: 'normal' }}>
                <img src={money} alt="money" style={{ width: 14 }} />
                {order.projectName}
              </div>
            }
          >
            <Grid columns={3} gap={8}>
              <Grid.Item span={2}>
                <table width={'100%'}>
                  <tr>
                    <td style={{ width: 120 }}>交款人:</td>
                    <td style={{ textAlign: 'right' }}>{order.qlrmc}</td>
                  </tr>
                  <tr>
                    <td>科目:</td>
                    <td style={{ textAlign: 'right' }}>
                      {order.sfkmmc.split('-')[0]}
                    </td>
                  </tr>
                  <tr>
                    <td>金额:</td>
                    <td style={{ textAlign: 'right' }}>{order.ssje}元</td>
                  </tr>
                </table>
              </Grid.Item>
              <Grid.Item style={{ paddingTop: 26, paddingLeft: 20 }}>
                {order.payStatus === 'wait' ||
                order.payStatus === null ||
                order.payStatus === undefined ? (
                  <Button
                    loading={payLoading}
                    size={'small'}
                    color="primary"
                    onClick={() => payUrl(order)}
                  >
                    Pay支付
                  </Button>
                ) : (
                  <Button disabled size={'small'}>
                    已支付
                  </Button>
                )}
              </Grid.Item>
            </Grid>
          </Card>
        );
      })}
      <Footer />
    </>
  );
}
