import Header from '../../common/Header';
import React, {useEffect, useState} from 'react';
import {wx, init} from '../../util/WxUtil';
import sm from '../../icon/sm.svg';
import {Mask, Modal, Form, Button, Input, Toast, Picker, Space, DotLoading} from 'antd-mobile';
import {RightOutline} from "antd-mobile-icons";
import {request} from "../../util/request";

interface City {
	label: string;
	value: string;
}

export default function Verification() {
	const [form] = Form.useForm();
	const [visible, setVisible] = React.useState(false);
	//行政区划列表
	const [xzqhList, setXzqhList] = useState<(City[])[]>([[]]);
	//选择的行政区划代码
	const [xzqh, setXzqh] = useState<(string | number | null)[]>([]);
	//是否可以点击查询按钮
	const [click, setClick] = useState<Boolean>(true);
	useEffect(() => {
		request.get<City[]>('/sinfConfig/list').then((data) => {
			setXzqhList([data]);
		});
		init();
	}, []);

	//选择行政区划代码
	function onXzqhChange(value: (string | number | null)[]) {
		setXzqh(value);
		//需要手动设置表单的xzqh值，表单组件无法同步Picker组件的数据
		form.setFieldValue('xzqh', value);
	}

	function onSubmit(formData: { zsbh: string, xzqh: string[] }) {
		if(!click){
			//简易防抖
			return;
		}
		setClick(false);
		request.get('/zsxx/queryZs/' + formData.xzqh[0] + '/' + formData.zsbh).then(() => {
			Toast.show({
				content: '核验成功！',
				icon: 'success'
			});
		}).finally(() => {
			setClick(true);
		})
	}

	return (
		<>
			<Header name={'查询结果核验'}/>
			<Form onFinish={onSubmit} form={form} footer={
				<Button block type='submit' color='primary' size='large'>
					查询
				</Button>
			} style={{position:'relative'}}>
				<div style={{display: !click ? 'flex' : 'none',position: 'absolute',
					left: 0,
					top: 0,
					right: 0,
					bottom: 0,
					backgroundColor: 'rgba(255,255,255,0.7)',
					zIndex: 999,
					fontSize: '16px',
					color: '#1677ff',
					alignItems: 'center',
					justifyContent: 'center',}}>
					<span>正在核验</span>
					<DotLoading color='currentColor'/>
				</div>
				<Form.Item name='xzqh' label='行政区划' rules={[{required: true, message: '请选择行政区划'}]}
									 layout='horizontal'>
					<Picker
						columns={xzqhList}
						value={xzqh}
						onConfirm={onXzqhChange}
					>
						{(items, {open}) => {
							return (
								<Space align='center' onClick={open}>
									{items.every(item => item === null) ? '请选择行政区' : items.map(item => item?.label ?? '请选择行政区').join('-')}
									<div className='picker-item'><RightOutline className='icon'/></div>
								</Space>
							)
						}}
					</Picker>
				</Form.Item>
				<Form.Item name='zsbh' label='证书编号' rules={[{required: true, message: '请输入证书编号'}]}
									 layout='horizontal'>
					<Input placeholder='请输入证书编号'/>
				</Form.Item>
				<div style={{height:'30px',lineHeight:'30px',fontSize:'12px',color:'#f5a854',backgroundColor:'#E7EEFD',whiteSpace:'nowrap',padding:'0 15px'}}>例：云(2023)个旧市不动产证明第0000001号</div>
			</Form>
			<div
				onClick={() => {
					setVisible(true);
					wx.ready(() => {
						wx.scanQRCode({
							needResult: 1,
							scanType: ['qrCode', 'barCode'],
							success: function (res: { resultStr: any }) {
								const result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
								Modal.alert({
									content: `业务号：${result},核验正常`,
									onConfirm: () => {
										console.log('Confirmed');
									},
								});
								setVisible(false);
							},
							fail: function (err: any) {
								setVisible(false);
							},
							cancel: function () {
								setVisible(false);
							},
						});
					});
				}}
				style={{width: '100%', textAlign: 'center'}}
			>
				<div>
					<img src={sm} alt="" width={100} style={{marginTop: '10vh'}}/>
				</div>
				<div style={{color: '#009FE8', fontSize: 16}}>扫一扫</div>
			</div>
			<Mask color="white" visible={visible}/>
		</>
	);
}
