/**
 * 公用JS
 */
import Stroage from "./Stroage";
import {requestEstate} from "./requestEstate";
import {Toast} from "antd-mobile";

class Common {

    /**
     * 获取token，如果没获取到则重新登录自动栋获取
     */
    getToken(type: Boolean) {
        let token: string = Stroage.getStroage("token");
        /* 只能自动登录一次，否则会重复自动登录 */
        if (!token && !type) {
            this.autoLogin().then(() => {
                this.getToken(true);
            });
        }
        return token;
    }

    /**
     * 自动登录
     */
    async autoLogin() {
        let openId: string = Stroage.getStroage("openid");
        if (!openId) {
            Toast.show("微信 openid 获取失败，请重新登录!")
        }
        const res = await requestEstate.post<any>("/sys/wxLogin", {openid: openId});
        if (!res || !res.success || !res.result) {
            Toast.show(res.message || "自动登录失效，请重新重新登录！");
            return;
        }
        /* 一窗 微信登录成功！ */
        Stroage.setStroage('token', res.result.token);
        return Promise.resolve();
    }


}

export default new Common();


