import React, { useEffect, useState } from 'react';
import Header from '../../common/Header';
import { Button, Form, Grid, Input, Tag } from 'antd-mobile';
import { request } from '../../util/request';
import { FormInstance } from 'antd-mobile/es/components/form';
import { Business } from './Xwbl';

interface Sdq {
  sname: string;
  shh: string;
  qname: string;
  qhh: string;
  dname: string;
  dhh: string;
  ywlsh: string;
}

export default function Sdq(props: any) {
  const formRef = React.createRef<FormInstance>();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(true);
  const { ywlsh } = props.match.params;

  const getSdq = () => {
    request.get<Sdq>(`/inquiry/getSdq/${ywlsh}`).then((data) => {
      formRef.current?.setFieldsValue(data);
    });
  };
  const getBusiness = () => {
    request.get<Business>(`/inquiry/getBusiness/${ywlsh}`).then((data) => {
      setEdit(data.submit === 'y');
    });
  };

  useEffect(() => {
    getSdq();
  }, []);
  useEffect(() => {
    getBusiness();
  }, []);
  const onFinish = (values: Sdq) => {
    setLoading(true);
    values.ywlsh = ywlsh;
    request
      .post<any>('/inquiry/sqd_save', values)
      .then((data) => {
        // 带参数跳转
        window.location.href = `#/zsxx/${ywlsh}`;
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Header name={'水电气'} />
      <div>
        <Form
          disabled={edit}
          name="form"
          ref={formRef}
          onFinish={onFinish}
          footer={
            <Grid columns={2} gap={12}>
              <Grid.Item>
                <Button
                  block
                  color="primary"
                  size="large"
                  onClick={() => (window.location.href = `/#/sqr/${ywlsh}`)}
                >
                  上一步
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  block
                  type="submit"
                  color="primary"
                  size="large"
                  loading={loading}
                >
                  下一步
                </Button>
              </Grid.Item>
            </Grid>
          }
        >
          <Form.Header>水电气过户信息</Form.Header>
          <div>
            <Tag color="warning">水表过户</Tag>
          </div>
          <Form.Item name="sname" label="供水企业名称" help="由卖方提供">
            <Input placeholder="请输入供水企业名称" />
          </Form.Item>
          <Form.Item name="shh" label="用户编号" help="由卖方提供">
            <Input placeholder="请输入用户编号" />
          </Form.Item>
          <div>
            <Tag color="warning">气表过户</Tag>
          </div>
          <Form.Item name="qname" label="供气企业名称" help="由卖方提供">
            <Input placeholder="请输入供气企业名称" />
          </Form.Item>
          <Form.Item name="qhh" label="用户编号" help="由卖方提供">
            <Input placeholder="请输入用户编号" />
          </Form.Item>
          <div>
            <Tag color="warning">电表过户</Tag>
          </div>
          <Form.Item name="dname" label="供电企业名称" help="由卖方提供">
            <Input placeholder="请输入供电企业名称" />
          </Form.Item>
          <Form.Item name="dhh" label="用户编号" help="由卖方提供">
            <Input placeholder="请输入用户编号" />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
