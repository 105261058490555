import axios, {AxiosRequestConfig} from 'axios';
import {Modal, Toast} from 'antd-mobile';
import Stroage from "./Stroage";

let baseURL = '';
/**
 * 判断是否是开发环境
 * @param NODE_ENV 环境变量
 * @param development 开发环境
 * @param production 生产环境
 */
if (process.env.NODE_ENV === 'development') {
    baseURL = '/estate';
} else if (process.env.NODE_ENV === 'production') {
    // baseURL = 'http://bdc.hhnrp.cn/estate';
    baseURL = '/estate';
}
// baseURL = 'http://192.168.3.51:8080/estate';
baseURL = '/estate';
console.log('baseURL', baseURL);

/**
 * @param timeout 超时时间 默认30秒
 */
const timeout = 300000;

/**
 * @param timeout 超时时间
 * @param baseURL 请求地址
 * @param withCredentials 是否携带cookie
 */
const service = axios.create({
    timeout,
    baseURL,
    //如需要携带cookie 该值需设为true
    withCredentials: false,
});

/**
 * 统一请求拦截 可配置自定义headers 例如 token、openid等
 * @param config 请求配置
 * @param error 错误信息
 * @returns Promise
 */
service.interceptors.request.use(
    (config: any) => {
        //配置自定义请求头
        config.headers.openid = Stroage.getStroage('openid');
        config.headers["X-Access-Token"] = Stroage.getStroage('token');
        return config;
    },
    (error) => {
        console.log(error);
        Promise.reject(error);
    }
);

/**
 * axios返回数据格式
 * @param data 业务数据
 * @param status 状态码
 * @param statusText 状态信息
 */
interface axiosTypes<T> {
    data: T;
    status: number;
    statusText: string;
}

/**
 * 后台响应数据格式
 * 该接口用于规定后台返回的数据格式，意为必须携带code、msg以及data
 * 而data的数据格式 由外部提供。如此即可根据不同需求，定制不同的数据格式
 * @param code 业务状态码
 * @param msg 业务状态信息
 * @param data 业务数据
 */
export interface responseTypes<T> {
    success: boolean;
    message: string;
    code: number;
    result: T;
}

/**
 * 核心处理代码 将返回一个promise 调用then将可获取响应的业务数据
 * @param method 请求方法
 * @param url 请求地址
 * @param params 请求参数
 * @param config 请求配置
 */
const requestHandler = <T>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    params: object = {},
    config: AxiosRequestConfig = {}
): Promise<T> => {
    let response: Promise<axiosTypes<responseTypes<T>>>;
    switch (method) {
        case 'get':
            response = service.get(url, {params: {...params}, ...config});
            break;
        case 'post':
            response = service.post(url, {...params}, {...config});
            break;
        case 'put':
            response = service.put(url, {...params}, {...config});
            break;
        case 'delete':
            response = service.delete(url, {params: {...params}, ...config});
            break;
    }

    return new Promise<any>((resolve, reject) => {
        return response
            .then((res) => {
                //业务代码 可根据需求自行处理
                const data = res.data;
                if (!data?.success) {
                    let e = JSON.stringify(data);
                    Toast.show({icon: 'fail', content: data.message, duration: 5000});
                    console.log(`请求错误：${e}`);
                    // 数据请求错误 使用reject将错误返回
                    reject(data);
                } else {
                    // 数据请求正确 使用resolve将结果返回
                    if (data.result) {
                        resolve(data);
                    } else {
                        // @ts-ignore
                        resolve(data);
                    }
                }
            })
            .catch((error) => {
                let e = JSON.stringify(error);
                if (error.response && error.response.status === 401) {
                    let datas = error.response.data;
                    if (datas.message.indexOf('失效') !== -1) {
                        Toast.show(datas.message);
                        /* 清除所有缓存 */
                        // Stroage.clearStroageAll();
                        setTimeout(() => {
                            // window.location.href = '/#/';
                        }, 1500)
                        return;
                    }
                    Modal.confirm({
                        content: datas.msg || datas.message,
                        onConfirm: () => {
                            window.location.href = '/#/me';
                        },
                    });
                } else {
                    Toast.show({content: e, duration: 3000});
                    console.log(`网络错误：${e}`);
                }
                reject(error);
            });
    });
};

/**
 * 统一调用方法
 * @param get get请求
 * @param post post请求
 * @param put put请求
 * @param delete delete请求
 */
const requestEstate = {
    get: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
        requestHandler<T>('get', url, params, config),
    post: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
        requestHandler<T>('post', url, params, config),
    put: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
        requestHandler<T>('put', url, params, config),
    delete: <T>(url: string, params?: object, config?: AxiosRequestConfig) =>
        requestHandler<T>('delete', url, params, config),
};

/**
 * 导出至外层，方便统一使用
 * @param request 请求方法
 * @param baseURL 请求地址
 */
export {requestEstate, baseURL};
