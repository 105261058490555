/**
 * 公共缓存方法
 */

class Stroage {

    /* 如要要用前缀区分，则需要把所有获取localStroage的方式调整为当前Stroage方式，不然会导致获取不到等问题 */
    private prefix: string = ""


    /**
     * 处理名称
     * @param _name
     */
    handleName(_name: string) {
        return this.prefix + _name;
    }

    /**
     * 设置缓存
     */
    setStroage(_name: string, _value: any) {
        window.localStorage.setItem(this.handleName(_name), _value);
    }

    /**
     * 获取缓存
     * @param _name
     */
    getStroage(_name: string) {
        return window.localStorage.getItem(_name) || "";
    }

    /**
     * 清除缓存
     * @param _name
     */
    clearStroage(_name: string) {
        window.localStorage.removeItem(_name);
    }

    /**
     * 清除所有缓存
     */
    clearStroageAll(){
        window.localStorage.clear();
    }
}

export default new Stroage();


