import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../../common/Header";
import {requestEstate} from "../../util/requestEstate";
import { DotLoading, Toast, SearchBar, Modal, Empty, ImageViewer } from "antd-mobile";
import {Pagination} from "antd";
import './Bszn.css';
import PdfViewYn from '../../common/PdfViewYn';

interface Guide {
	id: string,
	title: string,
	content: string,
	source: string,
	publishTime: string
}

function Bszn() {
	const [guideList, setGuideList] = useState<Array<Guide>>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const pagination = useRef<{
		current: number,//当前页,从1开始
		size: number,//每页数据个数
		total: number,//数据总数
	}>({
		current: 1,
		size: 10,
		total: 0
	});
	const modalRef = useRef(null);

	//获取数据列表
	async function getList(value?: string) {
		setLoading(true);
		try {
			const res = await requestEstate.get<{
				result: {
					records: Array<Guide>,
					pages: number,
					size: number,
					total: number,
					current: number
				}
			}>('/publish_content/publishContent/list', {
				type: '3',
				column: 'createTime',
				order: 'desc',
				title: value,
				pageSize: pagination.current.size,
				pageNo: value ? 1 : pagination.current.current
			});
			setGuideList(res.result.records);
			pagination.current.current = res.result.current;
			pagination.current.size = res.result.size;
			pagination.current.total = res.result.total;
		} catch (e) {
			console.log(e);
			Toast.show({icon: 'fail', content: '网络错误', duration: 5000});
		}
		setLoading(false);
	}

	//切换页码
	async function paginationChange(page: number, pageSize: number) {
		pagination.current.current = page;
		await getList();
	}

	const showPdf = useCallback((fileName:string, filePath:string) => {
		Modal.show({
			title: fileName,
			content: <PdfViewYn filePath={filePath} />,
			closeOnMaskClick: true,
			getContainer: modalRef.current,
			closeOnAction: true,
			actions: [
				{
					key: 'online',
					text: '关 闭',
					primary: true,
				},
			],
		});
	}, [modalRef]);

	const previewImage = useCallback((imageUrl: string) => {
		ImageViewer.Multi.show({ images: [imageUrl] })
	}, [modalRef]);
	const handleAnchorClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const anchor = (event.target as HTMLElement).closest('a');
		const imgElement = (event.target as HTMLElement).closest('img');
		if (anchor) {
			event.preventDefault();
			const href = anchor.getAttribute('data-href');
			const filename = anchor.getAttribute('data-filename');

			if (filename && filename.toLowerCase().endsWith('.pdf')) {
				showPdf(filename, href || '');
			} else if (href) {
				window.open(href, '_blank');
			}
		}
		if (imgElement) {
			event.preventDefault();
			const imgSrc = imgElement.getAttribute('src');
			if (imgSrc) {
				previewImage(imgSrc);
			}
		}
	};

	function handleContent(txt:string){
		// 创建临时 DOM 元素用于解析 HTML 字符串
		const tempDiv = document.createElement('div');
		tempDiv.innerHTML = txt;
		// 循环处理 a 标签
		tempDiv.querySelectorAll('a').forEach((anchor) => {
			let href = anchor.getAttribute('href') || "";
			let filename = anchor.getAttribute('download') || "";
			anchor.removeAttribute('href');
			anchor.setAttribute('data-href', href);
			anchor.setAttribute('data-filename', filename);
			anchor.removeAttribute('download');
		});
		// 循环处理 img 标签
		tempDiv.querySelectorAll('img').forEach((img) => {
			const imgSrc = img.getAttribute('src') || "";
			img.setAttribute('data-src', imgSrc);
		});
		return tempDiv.innerHTML;
	}

	function handleSetInnerHtml(content: string){
		return <div onClick={handleAnchorClick}>
			<div
				dangerouslySetInnerHTML={{
					__html: handleContent(content),
				}}
			/>
		</div>
	}

	//显示详情弹窗
	function showDetail(guide: Guide) {
		Modal.show({
			title: guide.title,
			content: handleSetInnerHtml(guide.content),
			closeOnMaskClick: true,
			getContainer: modalRef.current,
			closeOnAction: true,
			actions: [
				{
					key: 'online',
					text: '关 闭',
					primary: true,
				},
			],
		});
	}

	useEffect(() => {
		getList().then();
	}, []);
	return (
		<div className={'bszn-page'}>
			<Header name={'办事指南'}/>
			<div className={'page-content'}>
				{ (()=>{
					if(guideList.length > 0){
						return <>
							<div style={{ display: loading ? "flex" : "none" }} className="loading">
								<span>数据加载中</span>
								<DotLoading color="currentColor" />
							</div>
							<div className={"search-bar"}>
								<SearchBar placeholder="请输入关键词" showCancelButton onSearch={(value) => getList(value)} />
							</div>
							<div className={"guide-list"}>
								{guideList.map(guide => {
									return (
										<div className={"guide-item"} key={guide.id} onClick={() => showDetail(guide)}>
											<div className={"guide-title"}>{guide.title}</div>
											<div className={"guide-tips"}>
												<span>发布时间：{guide.publishTime}</span>
												<span>来源：{guide.source}</span>
											</div>
										</div>
									);
								})}
							</div>
							<Pagination style={{ display: guideList.length !== 0 ? "block" : "none" }} className={"pagination"}
													current={pagination.current.current} total={pagination.current.total}
													onChange={paginationChange} /></>
					}else {
						return <Empty description="暂无数据" />
					}
				})() }
				<div className={'detail-modal'} ref={modalRef}></div>
			</div>
		</div>
	);
}

export default Bszn;
