import Header from '../../../common/Header';
import React from 'react';
import { Tabs } from 'antd-mobile';
import Payment from './Payment';
import Bill from './Bill';

export default function Pay() {
  return (
    <>
      <Header name={'在线支付'} />
      <Tabs defaultActiveKey="1">
        <Tabs.Tab title="缴费" key="1">
          <Payment />
        </Tabs.Tab>
        <Tabs.Tab title="票据" key="2">
          <Bill />
        </Tabs.Tab>
      </Tabs>
    </>
  );
}
